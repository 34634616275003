import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { MarketConfigurationApi } from "api/marketConfigurationApi";
import { ApiClients } from "api/clients";
import { RootState } from "./configureStore";
import dateTimeHelper from "helpers/dateTimeHelper";
import { createAppSelector } from "./hooks";
import { LoadedData } from "./loadedData";

export interface MarketWithUrlSlug extends MarketConfigurationApi.Market {
  slug: string;
}

interface SiteConfigurationState {
  markets: LoadedData<MarketWithUrlSlug[]>;
  blockTradeFees: Record<string, LoadedData<MarketConfigurationApi.MarketFee[]>>;
  efpFees: Record<string, LoadedData<MarketConfigurationApi.MarketFee[]>>;
  exchangeFees: Record<string, LoadedData<MarketConfigurationApi.MarketFee[]>>;
  commodities: MarketConfigurationApi.ConfiguredMarketCommodity[];
  products: { loading: boolean; data: Record<string, MarketConfigurationApi.ConfiguredProductDetail> };
  marketProducts: Record<
    string,
    {
      loading: boolean;
      commodity: string;
      instrument: string;
      tradeDate: string;
      products: MarketConfigurationApi.ConfiguredProductDetail[];
    }
  >;

  productCodes: {
    year: number;
    marketId: string;
    instrument: string;
    products: MarketConfigurationApi.CodeLookupResponseItem[];
  }[];
}

// Define the initial state using that type
const initialState: SiteConfigurationState = {
  markets: {
    loading: false,
    data: [],
    error: undefined,
    lastLoaded: 0
  },
  blockTradeFees: {},
  efpFees: {},
  exchangeFees: {},
  commodities: [],
  products: { loading: false, data: {} },
  marketProducts: {},

  productCodes: []
};

export const loadMarkets = createAsyncThunk<MarketConfigurationApi.Market[], boolean | undefined, { state: RootState }>(
  "siteConfig/loadMarkets",
  async (_, g) => await ApiClients.marketConfiguration.marketsClient.listMarkets(true, g.signal),
  {
    condition: (force, api) => {
      const { data, error, lastLoaded, loading } = api.getState().SiteConfiguration.markets;
      if (loading) return false;

      if (data.length < 1 && !error) return true;

      return Date.now() - lastLoaded > (force === true ? 5000 : dateTimeHelper.MILLISECONDS_PER_MINUTE * 3);
    }
  }
);

const checkFeeState: (
  marketId: string,
  state: SiteConfigurationState | undefined,
  stateKey: keyof Pick<SiteConfigurationState, "exchangeFees" | "efpFees" | "blockTradeFees">
) => boolean = (marketId, state, stateKey) => {
  if (!marketId) return false;

  if (state == null || state[stateKey] == null || state[stateKey][marketId] == null) return true;

  const marketFees = state[stateKey][marketId];
  return marketFees.loading === false && Math.abs(Date.now() - marketFees.lastLoaded) > dateTimeHelper.MILLISECONDS_PER_MINUTE * 5;
};

export const loadMarketBlockTradeFees = createAsyncThunk<MarketConfigurationApi.MarketFee[], string, { state: RootState }>(
  "siteConfig/loadMarketBlockTradeFees",
  async (marketId, g) => await ApiClients.marketConfiguration.marketFeesClient.listAllBlockMarketFees(marketId, g.signal),
  {
    condition: (marketId, { extra, getState }) => {
      return (extra as any)?.force === true || checkFeeState(marketId, getState().SiteConfiguration, "blockTradeFees");
    }
  }
);

export const reloadMarketBlockTradeFees = createAsyncThunk<MarketConfigurationApi.MarketFee[], string, { state: RootState }>(
  "siteConfig/reloadMarketBlockTradeFees",
  async (marketId, g) => {
    return await loadMarketBlockTradeFees(marketId)(g.dispatch, g.getState, { force: true }).unwrap();
  }
);

export const loadMarketEFPFees = createAsyncThunk<MarketConfigurationApi.MarketFee[], string, { state: RootState }>(
  "siteConfig/loadMarketEFPFees",
  async (marketId, g) => await ApiClients.marketConfiguration.marketFeesClient.listAllExchangeForPhysicalMarketFees(marketId, g.signal),
  {
    condition: (marketId, api) => checkFeeState(marketId, api.getState().SiteConfiguration, "efpFees")
  }
);

export const loadMarketExchangeFees = createAsyncThunk<MarketConfigurationApi.MarketFee[], string, { state: RootState }>(
  "siteConfig/loadMarketExchangeFees",
  async (marketId, g) => await ApiClients.marketConfiguration.marketFeesClient.listAllExchangeMarketFees(marketId, g.signal),
  {
    condition: (marketId, api) => checkFeeState(marketId, api.getState().SiteConfiguration, "exchangeFees")
  }
);

export const reloadMarketExchangeFees = createAsyncThunk<MarketConfigurationApi.MarketFee[], string, { state: RootState }>(
  "siteConfig/loadMarketExchangeFees",
  async (marketId, g) => await ApiClients.marketConfiguration.marketFeesClient.listAllExchangeMarketFees(marketId, g.signal)
);

export const loadProducts = createAsyncThunk("siteConfig/loadProducts", async (s, g) => {
  return await ApiClients.marketConfiguration.productsClient.listAllProducts();
});

export const loadMarketProducts = createAsyncThunk<
  MarketConfigurationApi.ConfiguredProductDetail[],
  {
    marketId: string;
    tradeDate: string;
    commodity?: MarketConfigurationApi.CommodityType;
    instrument?: string;
  },
  { state: RootState }
>(
  "siteConfig/loadMarketProducts",
  async (s, api) => {
    return await ApiClients.marketConfiguration.productsClient.getMarketProductsForTradeDate(
      s.marketId,
      s.commodity,
      s.instrument as any,
      s.tradeDate,
      undefined,
      api.signal
    );
  },
  {
    condition: (params, state) => {
      if (!params || !params.marketId) return false;

      const currentState = state.getState().SiteConfiguration.marketProducts;
      if (currentState == null || currentState[params.marketId] == null) return true;

      if (currentState[params.marketId].loading === true) return false;

      return (
        currentState[params.marketId].commodity.toLowerCase() !== params.commodity?.toLowerCase() ||
        currentState[params.marketId].instrument.toLowerCase() !== params.instrument?.toLowerCase() ||
        currentState[params.marketId].tradeDate !== params.tradeDate ||
        currentState[params.marketId].products.length < 1
      );
    }
  }
);

export const loadProductCodeList = createAsyncThunk<
  {
    year: number;
    marketId: string;
    products: { [key in keyof typeof MarketConfigurationApi.InstrumentType]?: MarketConfigurationApi.CodeLookupResponseItem[] };
  }[],
  { year: number; marketId: string }[],
  { state: RootState }
>(
  "siteConfig/loadProductCodeList",
  async (query, g) =>
    await Promise.all(
      query.map(x =>
        ApiClients.marketConfiguration.productsClient
          .getMarketProductCodesByYear(x.marketId, MarketConfigurationApi.CommodityType.Electricity, x.year, g.signal)
          .then(y => ({
            year: x.year!,
            marketId: x.marketId!,
            products: y
          }))
      )
    )
);

export const selectAllMarkets = createAppSelector(
  [(state: RootState) => state.SiteConfiguration.markets, (_: RootState, includeDisabled?: boolean) => includeDisabled],
  (markets: LoadedData<MarketWithUrlSlug[]>, includeDisabled?: boolean) => ({
    error: markets.error,
    lastLoaded: markets.lastLoaded,
    loading: markets.loading,
    markets: markets.data?.filter(x => x.isEnabled !== false || includeDisabled) || []
  })
);

export const selectMarkets = createAppSelector(
  [
    (state: RootState) => state.SiteConfiguration.markets.data,
    (_: RootState, commodity?: MarketConfigurationApi.CommodityType) => commodity
  ],
  (markets: MarketWithUrlSlug[], commodity?: MarketConfigurationApi.CommodityType) => {
    return markets?.filter(x => commodity == null || x.commodities?.some(y => y.commodity === commodity)) || [];
  },
  {
    memoizeOptions: {
      resultEqualityCheck: (a: MarketConfigurationApi.Market[], b: MarketConfigurationApi.Market[]) => {
        return a === b || (a.length === b.length && a.every(x => b.some(y => y.id === x.id)));
      }
    }
  }
);

export const selectElectricityMarketById = createAppSelector(
  [(state: RootState) => state.SiteConfiguration.markets.data, (_: RootState, marketId?: string) => marketId],
  (markets: MarketConfigurationApi.Market[], marketId?: string) => {
    const market = markets.find(
      x => x.id === marketId && x.commodities?.some(y => y.commodity === MarketConfigurationApi.CommodityType.Electricity) === true
    );
    if (!market) return undefined;

    return {
      market,
      instruments: [
        ...new Set(market.commodities?.find(x => x.commodity === MarketConfigurationApi.CommodityType.Electricity)?.instruments || [])
      ]
    };
  }
);

export const selectMarketById = createAppSelector(
  [
    (state: RootState) => state.SiteConfiguration.markets.data,
    (_: RootState, marketId: string, commodity: MarketConfigurationApi.CommodityType) => ({ marketId, commodity })
  ],
  (markets: MarketConfigurationApi.Market[], { marketId, commodity }) => {
    const market = markets.find(x => x.id === marketId && x.commodities?.some(y => y.commodity === commodity) === true);
    if (!market) return undefined;

    return {
      market,
      instruments: [...new Set(market.commodities?.find(x => x.commodity === commodity)?.instruments || [])]
    };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: (
        a?: {
          market: MarketConfigurationApi.Market;
          instruments: MarketConfigurationApi.InstrumentType[];
        },
        b?: {
          market: MarketConfigurationApi.Market;
          instruments: MarketConfigurationApi.InstrumentType[];
        }
      ) => {
        const r = a === b || (a?.market?.id || "") === (b?.market?.id || "");
        return r;
      }
    }
  }
);

export const selectMarketElectricityProductByCode = createAppSelector(
  [(state: RootState) => state.SiteConfiguration.products, (_, filter: { marketId: string; code: string }) => filter],
  (products, filter) => {
    return Object.values(products.data).find(
      x => x.market?.id === filter.marketId && x.tradingPeriods != null && x.tradingPeriods.some(y => y.productCode === filter.code)
    );
  }
);

interface SelectProduct {
  isLoading: boolean;
  product?: MarketConfigurationApi.ConfiguredProductDetail;
  tradePeriod?: MarketConfigurationApi.TradePeriodInstance;
}

export const selectMarketProduct = createAppSelector<
  [
    (state: RootState) => { loading: boolean; data: Record<string, MarketConfigurationApi.ConfiguredProductDetail> },
    (state: RootState) => Record<string, { loading: boolean; commodity: string; instrument: string; tradeDate: string }>,
    (state: RootState) => boolean,
    (
      state: RootState,
      filter: { marketId: string; productId: string; productCode: string; instrument?: string }
    ) => { marketId: string; productId: string; productCode: string; instrument?: string }
  ],
  SelectProduct
>(
  [
    (state: RootState) => state.SiteConfiguration.products,
    (state: RootState) => state.SiteConfiguration.marketProducts,
    (state: RootState) => state.MarketDeals.loading,
    (_, filter) => filter
  ],
  (products, marketProducts, currentDealLoading, { marketId, productId, productCode, instrument }) => {
    const code = productCode.toLowerCase();
    const matchingProductPeriod = Object.values(products.data)
      .filter(
        x =>
          x.market?.id === marketId &&
          x.tradingPeriods != null &&
          (instrument == null || new RegExp(instrument, "gi").test(x.instrument || ""))
      )
      .flatMap(x => x.tradingPeriods!.map(y => ({ product: x, tradePeriod: y })))
      .filter(x => !productId || x.product.identifier === productId)
      .find(x => x.tradePeriod.productCode?.toLowerCase().startsWith(code));

    const product = !!productId ? products.data[productId] : matchingProductPeriod?.product;

    return {
      product: product || matchingProductPeriod?.product,
      tradePeriod: matchingProductPeriod?.tradePeriod,
      isLoading:
        products.loading ||
        marketProducts == null ||
        marketProducts[marketId]?.loading == null ||
        marketProducts[marketId].loading ||
        currentDealLoading
    };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: (a: SelectProduct, b: SelectProduct) => {
        if (a === b) return true;
        if (a.isLoading !== b.isLoading) return false;

        if (a.product?.identifier !== b.product?.identifier) return false;
        if (a.tradePeriod?.productCode !== b.tradePeriod?.productCode) return false;

        return true;
      }
    }
  }
);
export const selectMarketElectricityProducts = (state: RootState, id?: string) =>
  selectMarketProducts(state, id, MarketConfigurationApi.CommodityType.Electricity);

export const selectCommodities = createAppSelector(
  [(state: RootState) => state.SiteConfiguration.commodities],
  commodities => {
    return (
      commodities.map(x => {
        return {
          commodity: x.commodity,
          marketIds: x.markets?.map(y => y.market?.id!).filter(y => !!y) || []
        };
      }) || []
    );
  },
  {
    memoizeOptions: {
      resultEqualityCheck: (
        a: { commodity?: MarketConfigurationApi.CommodityType; marketIds: string[] }[],
        b: { commodity?: MarketConfigurationApi.CommodityType; marketIds: string[] }[]
      ) => {
        if (!Array.isArray(a) || !Array.isArray(b)) return false;
        if ((a || []).length !== (b || []).length) return false;

        return a.every(x => {
          const match = b.find(y => y.commodity === x.commodity);
          if (!match) return false;

          return match.marketIds.length === x.marketIds.length && x.marketIds.every(y => match.marketIds.includes(y));
        });
      }
    }
  }
);

export const selectMarketProducts = createAppSelector(
  [
    (state: RootState) => state.SiteConfiguration.products,
    (state: RootState) => state.SiteConfiguration.markets,
    (state: RootState) => state.SiteConfiguration.marketProducts,
    (_, marketId?: string, commodity?: MarketConfigurationApi.CommodityType, tradeDate?: string) => ({
      marketId,
      commodity: commodity,
      tradeDate
    })
  ],
  (products, markets, marketProducts, search) => {
    const loading = products.loading || (!!search.marketId && marketProducts[search.marketId]?.loading === true);
    const marketEntry = !!search.marketId
      ? Object.entries(marketProducts)
          .filter(
            x =>
              x[0] === search.marketId &&
              (!search.commodity || x[1].commodity === search.commodity) &&
              (!search.tradeDate || x[1].tradeDate === search.tradeDate)
          )
          .reduce<MarketConfigurationApi.ConfiguredProductDetail[]>((list, n) => [...list, ...n[1].products], [])
      : undefined;

    return {
      isLoading: loading,
      products: loading
        ? []
        : marketEntry ||
          Object.values(products.data).filter(
            x => (!search.marketId || x.market?.id === search.marketId) && (!search.commodity || x.commodity === search.commodity)
          )
    };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: (a, b) => {
        if (a.isLoading !== b.isLoading) return false;

        if (a.products?.length !== b.products?.length) return false;
        if (a.products === b.products) return true;

        return a.products
          .map((x: MarketConfigurationApi.ConfiguredProductDetail) => x.identifier)
          .every(
            (x: string | undefined) => b.products.find((y: MarketConfigurationApi.ConfiguredProductDetail) => y.identifier === x) != null
          );
      }
    }
  }
);

export const selectClearerAccounts = createAppSelector(
  [(state: RootState) => state.ConfigSagaState.DefaultFees?.reply?.Clearers, (_: RootState, clearerId?: number) => clearerId],
  (clearers, id) => (id == null ? [] : (clearers || []).find(x => x.Name.id === id)?.Accounts || [])
);

const generateSlug = <T extends MarketConfigurationApi.Market>(market: T): MarketWithUrlSlug => ({
  ...market,
  slug: market.shortName?.toLowerCase().replace(/[^a-z\d]+/gi, "-") || ""
});

export const siteConfigurationSlice = createSlice({
  name: "siteConfig",
  initialState,
  reducers: {
    saveProductDetail: (state, action: PayloadAction<MarketConfigurationApi.ConfiguredProductDetail>) => {
      if (action.payload.identifier == null) return;

      state.products.data[action.payload.identifier] = action.payload;
    }
  },

  extraReducers: builder => {
    builder
      .addCase(loadMarkets.pending, state => {
        const newStateValue = {
          ...state.markets,
          loading: true,
          lastLoaded: Date.now()
        };

        state.markets = newStateValue;
        return state;
      })
      .addCase(loadMarkets.fulfilled, (state, action) => {
        const newData = (action.payload || []).map(generateSlug);
        const newStateValue = {
          ...state.markets,
          loading: false,
          error: undefined,
          lastLoaded: Date.now()
        };

        if (newData.length > 0) {
          newStateValue.data = newData;
        }

        state.markets = newStateValue;

        for (const market of newStateValue.data) {
          if (!!market.id && !Object.keys(state.blockTradeFees).includes(market.id)) {
            state.blockTradeFees[market.id] = { data: [], loading: false, error: undefined, lastLoaded: 0 };
          }
        }
      })
      .addCase(loadMarkets.rejected, (state, action) => {
        state.markets = {
          ...state.markets,
          loading: false,
          error: action.error.message
        };
      })

      .addCase(loadMarketBlockTradeFees.pending, (state, action) => {
        const marketFees: LoadedData<MarketConfigurationApi.MarketFee[]> = state.blockTradeFees[action.meta.arg] || {
          data: [],
          loading: true,
          error: undefined,
          lastLoaded: 0
        };
        marketFees.loading = true;

        state.blockTradeFees[action.meta.arg] = marketFees;
      })
      .addCase(loadMarketBlockTradeFees.fulfilled, (state, action) => {
        state.blockTradeFees[action.meta.arg].lastLoaded = Date.now();
        state.blockTradeFees[action.meta.arg].loading = false;
        state.blockTradeFees[action.meta.arg].data = action.payload;
        state.blockTradeFees[action.meta.arg].error = undefined;
      })

      .addCase(loadMarketEFPFees.pending, (state, action) => {
        const marketFees: LoadedData<MarketConfigurationApi.MarketFee[]> = state.efpFees[action.meta.arg] || {
          data: [],
          loading: true,
          error: undefined,
          lastLoaded: 0
        };
        marketFees.loading = true;

        state.efpFees[action.meta.arg] = marketFees;
      })
      .addCase(loadMarketEFPFees.fulfilled, (state, action) => {
        state.efpFees[action.meta.arg].lastLoaded = Date.now();
        state.efpFees[action.meta.arg].loading = false;
        state.efpFees[action.meta.arg].data = action.payload;
        state.efpFees[action.meta.arg].error = undefined;
      })

      .addCase(loadMarketExchangeFees.pending, (state, action) => {
        const marketFees: LoadedData<MarketConfigurationApi.MarketFee[]> = state.exchangeFees[action.meta.arg] || {
          data: [],
          loading: true,
          error: undefined,
          lastLoaded: 0
        };
        marketFees.loading = true;

        state.exchangeFees[action.meta.arg] = marketFees;
      })
      .addCase(loadMarketExchangeFees.fulfilled, (state, action) => {
        state.exchangeFees[action.meta.arg].lastLoaded = Date.now();
        state.exchangeFees[action.meta.arg].loading = false;
        state.exchangeFees[action.meta.arg].data = action.payload;
        state.exchangeFees[action.meta.arg].error = undefined;
      })

      .addCase(loadProducts.pending, state => {
        state.products.loading = true;
      })
      .addCase(loadProducts.rejected, state => {
        state.products.loading = false;
      })
      .addCase(loadProducts.fulfilled, (state, action) => {
        state.commodities = action.payload.commodities || [];
        state.products = {
          loading: false,
          data: (
            action.payload?.commodities?.flatMap(
              x =>
                x.markets?.flatMap(
                  y =>
                    y.instruments?.flatMap(z =>
                      [
                        ...(z.regions?.flatMap(a => a.products) || []),
                        ...(z.categories?.flatMap(b => b.regions?.flatMap(c => c.products) || []) || [])
                      ]
                        .filter(x => !!x)
                        .map(x => x!)
                    ) || []
                ) || []
            ) || []
          ).reduce<Record<string, MarketConfigurationApi.ConfiguredProductDetail>>((a, b) => {
            a[b.identifier!] = b;
            return a;
          }, {})
        };
      })

      .addCase(loadMarketProducts.pending, (state, action) => {
        state.marketProducts[action.meta.arg.marketId] = {
          ...state.marketProducts[action.meta.arg.marketId],
          products: [],
          loading: true
        };
      })
      .addCase(loadMarketProducts.fulfilled, (state, action) => {
        state.marketProducts[action.meta.arg.marketId] = {
          loading: false,
          commodity: action.meta.arg.commodity || "",
          instrument: action.meta.arg.instrument || "",
          tradeDate: action.meta.arg.tradeDate,
          products: action.payload || []
        };

        for (const product of action?.payload || []) {
          if (product.identifier == null) continue;
          state.products.data[product.identifier] = product;
        }
      })
      .addCase(loadMarketProducts.rejected, (state, action) => {
        state.marketProducts[action.meta.arg.marketId].loading = false;
      })

      .addCase(loadProductCodeList.pending, (state, action) => {
        state.productCodes = [
          ...state.productCodes,
          ...action.meta.arg.map(x => ({ year: x.year, marketId: x.marketId, instrument: "", products: [] }))
        ];
      })
      .addCase(loadProductCodeList.fulfilled, (state, action) => {
        const newData = action.payload.flatMap(x =>
          Object.entries(x.products).map(y => ({
            year: x.year,
            marketId: x.marketId,
            instrument: y[0],
            products: y[1]
          }))
        );

        state.productCodes = [
          ...state.productCodes.filter(x =>
            newData.every(y => x.year !== y.year && x.marketId !== y.marketId && x.instrument !== y.instrument)
          ),
          ...newData
        ];
      })
      .addCase(loadProductCodeList.rejected, (state, action) => {});
  }
});

export const { saveProductDetail } = siteConfigurationSlice.actions;

export default siteConfigurationSlice.reducer;
